import React from "react";
import { createDisposableModal } from "react-disposable-modal";
import PropTypes from "prop-types";
import { handleError } from "src/error";
import Cancelable from "react-disposable-decorator";
import { from } from "rxjs";
import { pluck } from "rxjs/operators";

/* Returns an rxjs6 observable where if you dispose the subscription, the modal is
 * unmounted from the DOM. It onNexts one value, the account that was either created
 * or modified (to syncEmail or syncCalendar).
 */
export function connectAccountModal(props) {
  return createDisposableModal(ExternallyExportedConnectAccount, props);
}

/* This component is the external api used by calendar-ui and maybe other sofe services
 * to connect an email/calendar account. We do not expose the entirety of the AddWizard
 * implementation details to the outside services, but rather a more simplified API.
 *
 */
@Cancelable
export default class ExternallyExportedConnectAccount extends React.Component {
  static propTypes = {
    // Required
    callerContext: PropTypes.oneOf(["prompt-for-email", "prompt-for-calendar", "prompt-for-new"]).isRequired,
    accounts: PropTypes.array.isRequired,

    // Optional

    // Provided by react-disposable-modal
    onNext: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onCompleted: PropTypes.func.isRequired,
  };
  state = {
    accounts: this.props.accounts,
    AddWizard: null,
  };
  componentDidMount() {
    this.props.cancelWhenUnmounted(
      from(import(/* webpackChunkName: "add-wizard" */ "./add-wizard.component.js"))
        .pipe(pluck("default"))
        .subscribe((AddWizard) => this.setState({ AddWizard }), handleError)
    );
  }
  render() {
    const { AddWizard } = this.state;
    if (AddWizard) {
      /* Notes:
       * - We do not currently support triggering a client import for an existing account from this component
       * - We do not support custom headers because that is a React-specific thing and this is externally exported to non-react apps
       * - We can make forwardToSettings configurable in the future if we need to
       */
      return (
        <AddWizard
          close={this.props.onCompleted}
          emailAccountId={null}
          step={1}
          customHeader={null}
          skipClientImport={this.props.skipClientImport || false}
          accounts={this.state.accounts}
          newAccountConnected={this.newAccountConnected}
          existingAccountUpdated={this.existingAccountUpdated}
          callerContext={this.props.callerContext}
          forwardToSettings={false}
        />
      );
    } else {
      return null;
    }
  }
  newAccountConnected = (newAccount) => {
    this.setState((prevState) => ({
      accounts: [...prevState.accounts, newAccount],
    }));

    this.props.onNext(newAccount);
  };
  existingAccountUpdated = (updatedAccount) => {
    this.setState((prevState) => ({
      accounts: prevState.accounts.map((account) => (account.id === updatedAccount.id ? updatedAccount : account)),
    }));
    this.props.onNext(updatedAccount);
  };
}
